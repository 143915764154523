import React from 'react'
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';

import notfound from './../notfound.png';
import loading from './../loading.gif';
import { getProductsByCategory, putProductsByCategory } from './../CacheService';



function ItensPage(props) {
    const { id } = props.match.params;
    const [produtos, setProdutos] = useState(getProductsByCategory(id));
    const [isLoading, setLoading] = useState(true);;

    useEffect(() => {
        fetch(`https://apicardapio.metresistemas.com.br/api/product/category/${id}`)
            .then(res => res.json())
            .then(result => {
                setProdutos(result);
                setLoading(false);
            })
    }, [])


    function addItem(product) {
        console.log(product);
        localStorage.setItem("item", JSON.stringify(product));
    }

    return <>
        <div className="corpo" >
            <div className="grupos">
                {isLoading ?
                    <div style={{
                        width: "100%", display: "flex",
                        textAlign: "center", alignContent: "center", justifyContent: "center"
                    }}>
                        <img src={loading} style={{ maxWidth: "250px", width: "250px" }}></img>
                    </div> : <></>
                }

                {isLoading === false && produtos && produtos.length === 0 ?
                    <div>
                        <center>
                            <h3 className="zero-text-color">Nenhum Produto Cadastrado</h3>
                        </center>
                        <div style={{ width: '100%', display: 'flex' }}>

                            <img src={notfound} style={{ margin: '0 auto', width: '100%', maxWidth: '360px' }} alt="notfound" />
                        </div>
                    </div>
                    :
                    <div>
                        {produtos.map((grupo) => (
                            <Link key={grupo.id} to={`/detalhe/${grupo.id}`} onClick={() => addItem(grupo)} title={grupo.name}>
                                <div className="grupo shadow" >
                                    <div className="nome primary-text-color f14">
                                        {grupo.name.toUpperCase()}<br />
                                        <small className="secondary-text-color f100 fs13" style={{ display: "block", marginBottom: "10px" }}>
                                            {grupo.sinopse}
                                        </small>
                                        <strong className="zero-text-color" style={{ marginBottom: "5px" }}>{Number(grupo.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                    </div>
                                    <div className="foto">
                                        <img src={grupo.photo} alt={grupo.name} />
                                    </div>

                                </div>
                            </Link>
                        ))}
                    </div>
                }
            </div>
        </div>
    </>
}

export default ItensPage;