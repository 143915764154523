import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
import { CompanyContext } from '../providers/Provedor';




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        marginLeft: "5px",
        color: "#ef7522"
    },
}));
function Header(props) {
    const classes = useStyles();
    const location = useLocation();
    const empresa = React.useContext(CompanyContext);

    const param = location.pathname.split('/')[1];
    var pattern = /^\d+$/;
    const isMain = pattern.test(param);



    return <div className={classes.root}>
        {empresa && empresa.id ?
            <>
                <AppBar position="static">
                    <Toolbar>
                        {isMain ?
                            <Avatar alt="Remy Sharp" src={empresa.photo !== undefined ? empresa.photo : ''} />
                            :
                            <IconButton edge="start" color="inherit" aria-label="menu" >
                                <Link onClick={() => window.history.go(-1)} className="zero-text-color">
                                    <ArrowBackIcon />
                                </Link>
                            </IconButton>
                        }
                        <Typography variant="h6" className={classes.title}>
                            {empresa.name ? empresa.name : ''}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </>
            :
            <></>
        }


    </div>
}

export default Header;