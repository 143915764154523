function getGrupos() {
    if (localStorage.getItem('grupos')) {
        return JSON.parse(localStorage.getItem('grupos'));
    } else {
        return [];
    }
}

function putGrupos(grupos) {
    localStorage.setItem('grupos', JSON.stringify(grupos));
}


function getPromos() {
    if (localStorage.getItem('promos')) {
        return JSON.parse(localStorage.getItem('promos'));
    } else {
        return [];
    }
}

function putPromos(promos) {
    localStorage.setItem('promos', JSON.stringify(promos));
}



function getProductsByCategory(cat) {
    if (localStorage.getItem(`${cat}-produtos`)) {
        return JSON.parse(localStorage.getItem(`${cat}-produtos`));
    } else {
        return [];
    }
}


function putProductsByCategory(cat, products) {
    localStorage.setItem(`${cat}-produtos`, JSON.stringify(products));

}


function clearStorage(){
    localStorage.removeItem('grupos');
    localStorage.removeItem('promos');
}


export { getGrupos, putGrupos, getPromos, putPromos, getProductsByCategory, putProductsByCategory, clearStorage }