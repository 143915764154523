import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clearStorage } from '../CacheService';


export const CompanyContext = React.createContext();


const CompanyProvider = (props) => {

    const location = useLocation();
    const param = location.pathname.split('/')[1];
   
    var idCompany = null;
    var isMain = false;
    if (param) {
        var pattern = /^\d+$/;
        isMain = pattern.test(param);
        if (isMain) {
            idCompany = param;
        }
    }

    const [empresa, setEmpresa] = useState(null);

    useEffect(() => {

        const fetchCompany = async () => { 
            await fetch(`https://apicardapio.metresistemas.com.br/api/empresa/${idCompany}`)
            .then(response => response.json())
            .then(data => {
                setEmpresa(data[0]);
                localStorage.setItem('id', idCompany);
            }).catch(error => {
                console.log('error ',error);
            });
        }

        if (!idCompany && localStorage.getItem('id')) {
            idCompany = localStorage.getItem('id');
        } else if ((idCompany && localStorage.getItem('id')) && idCompany !== localStorage.getItem('id')) {
            clearStorage();
        }
        if (idCompany) {
            fetchCompany();
           
        }
    }, []);
    return (
        <CompanyContext.Provider value={empresa}>
            {props.children}
        </CompanyContext.Provider>
    )
}

export default CompanyProvider;