import React, { useState, useEffect,  useContext  }  from 'react';
import Header from './components/Header';
import { Route, Switch } from 'react-router-dom';
import HomeApp from './pages/HomePage';
import DetalhePage from './pages/DetalhePage';
import ItensPage from './pages/ItensPage';
import { CompanyContext } from './providers/Provedor';
import logo from './logo.png';



function App() {

  const empresa = useContext(CompanyContext);
  
  


  return (
    <div className="App">
      {empresa?.id ?
        <>
          <Header />
              <Switch>
                <Route path="/:par" exact component={HomeApp} />
                <Route path="/itens/:id" exact component={ItensPage} />
                <Route path="/detalhe/:idproduto" exact component={DetalhePage} />
              </Switch>
        </>
        :
        <>
          <div className="loading">Loading&#8230;</div>
        </>
      }
      <div className="footer">
        <a href="https://metresistemas.com.br" target="_blank" className="zero-text-color" rel="noreferrer">
          <img src={logo} alt="logo" />
          Cardápio Digital Metre
        </a>
      </div>
    </div >
  );
}

export default App;
