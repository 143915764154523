import { makeStyles } from "@material-ui/core";
import { useState } from "react";



const useStyles = makeStyles((theme) => ({
    preco: {
        position: "absolute",
        right: "20px",
        marginTop: "-32px",
        fontWeight: "bold",
        color: "white",
        background: "#f18128!important",
        padding: "8px",
        borderRadius: "15px"
    },
    foto: {
        maxWidth: "500px",
        textAlign: "center",
        margin: "0 auto",
    }
}));

function DetalhePage(props) {
    const classes = useStyles();


    console.log(JSON.parse(localStorage.getItem("item")));
    const [produto] = useState(JSON.parse(localStorage.getItem("item")));
    return (
        <div >
            <div className={classes.foto}>
                <img src={produto.photo} style={{ width: "100%" }} alt={produto.name} />
            </div>
            <div className="detalhe pl-24 mt10" style={{ paddingLeft: "24px" }}>
                <div className={classes.preco}>{Number(produto.price_promotional ? produto.price_promotional : produto.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                <span className="secondary-text-color f600 f14">Categoria</span>
                <h3 className="mt0 primary-text-color mb4">{produto.name}</h3>
                <span className="secondary-text-color f12 f300">{produto.sinopse}<br />{produto.price_promotional ? <strong className="zero-text-color"> EM PROMOÇÃO</strong> : ''}</span>
            </div>
        </div>
    )

}

export default DetalhePage;