import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import React from 'react';
import { CompanyContext } from '../providers/Provedor';

import { getPromos, putPromos } from './../CacheService';


function Promocoes(props) {
    const [promocoes, setPromocoes] = useState(getPromos());
    const empresa = React.useContext(CompanyContext);

    useEffect(() => {
        fetch(`https://apicardapio.metresistemas.com.br/api/product/promos/${empresa.id}`)
            .then(res => res.json())
            .then(result => {
                setPromocoes(promocoes);
            })
    }, [])

    function addItem(product) {
        localStorage.setItem("item", JSON.stringify(product));
    }

    return <>
        <div>
            {promocoes && promocoes.length > 0 ?
                <div>
                    <h3 className="zero-text-color f600 mb4">Promoções de hoje</h3>
                    <div className="promocionais">
                        {
                            promocoes.map((promo, index) => (
                                <Link key={promo.id} to={`/detalhe/${promo.id}`} onClick={()=>addItem(promo)}>
                                    <div className="promocao">
                                        <div style={{ backgroundImage: `url("${promo.photo}") `, width: 150, height: 200, backgroundRepeat: "no-repeat", position: "relative", borderRadius: "5px" }} className="shadow">
                                            <div className="precos">
                                                <small><del style={{ color: "white", fontWeight: "bolder" }}><div className="de">de {promo.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div></del></small>
                                                <div className="por">
                                                    <small>POR</small> <strong>{promo.price_promotional.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mb0 mt5 primary-text-color">{promo.name.toUpperCase()}</h4>
                                        <small className="secondary-text-color">{promo.sinopse.substring(0, 20)}... {props.abc}</small>
                                    </div>
                                </Link>
                            ))
                        }

                    </div>
                </div>
                :
                <div>

                </div>
            }

        </div>

    </>
}

export default Promocoes;