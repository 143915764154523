import Grupos from '../components/Grupos';
import Promocoes from '../components/Promocoes';

function HomePage() {
    return <>
        <div className="corpo ">
            <div className="mensagem mb4">
                <h2 className="mb0 primary-text-color f300 ">Olá visitante,</h2>
                <span className="secondary-text-color">conheça nosso cardápio</span>
            </div>
            <Promocoes />
            <Grupos />
        </div>
    </>
}

export default HomePage;