import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import './Grupo.css';
import React from 'react';
import { CompanyContext } from '../providers/Provedor';
import { getGrupos, putGrupos } from './../CacheService';

function Grupos() {
    const [grupos, setGrupos] = useState((getGrupos()));
    const empresa = React.useContext(CompanyContext);

    useEffect(() => {
      
            fetch(`https://apicardapio.metresistemas.com.br/api/categories/${empresa.id}`)
                .then(res => res.json())
                .then(result => {
                    setGrupos(result);
                })
    }, [])
    return <>
        <div>
            {grupos && grupos.length > 0 ?
                <div>
                    <h3 className="zero-text-color f600 mb2">Selecione uma categoria</h3>
                    <div className="grupos">
                        {grupos.map((grupo) => (
                            <Link key={grupo.id} to={`/itens/${grupo.id}`}>
                                <div className="grupo shadow" >
                                    <div className="nome primary-text-color">
                                        {grupo.name.toUpperCase()}
                                    </div>
                                    <div className="foto">
                                        <img src={grupo.photo} alt={grupo.name} />
                                    </div>

                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                :
                <div></div>
            }

        </div>

    </>
}

export default Grupos;